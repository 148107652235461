import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";

function Customer() {
  const [customer, setCustomer] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 250,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 250,
    },
  ];

  useEffect(() => {
    getAllCustomer(page, limit, "", "");
  }, []);

  const getAllCustomer = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/customer?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setCustomer(res.data.customer);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllCustomer(page, limit, "", "");
    if (item.length === 0) {
      getAllCustomer(page, limit, "", "");
    } else {
      getAllCustomer(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/customer/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setCustomer(res.data.customer);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={customer}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllCustomer(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllCustomer(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
    </Box>
  );
}

export default Customer;
