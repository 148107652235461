import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import getCroppedImg from "./cropImage";
import { imgUrl } from "./../../url";

const DragsAndDrop = (props) => {
  function dataURLtoFile(dataUrl, filename) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const [previewImg, setPreviewImg] = useState("");
  const [cropImg, setCropImg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.addEventListener("load", () => setCropImg(reader.result));
    handleOpen();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  const setImage = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.addEventListener("load", () => setPreviewImg(reader.result));
    props.uploadFile(data);
  };

  return (
    <>
      <Box {...getRootProps()} sx={{ border: "1px dashed black" }}>
        <Box
          component={"img"}
          // width={props.width ? props.width : "auto"}
          width={"100%"}
          // height={props.height ? props.height : "auto"}
          src={
            props.imgKey
              ? `${imgUrl}/${props.imgKey}`
              : previewImg
              ? previewImg
              : "/images/choose.jpg"
          }
          alt="Default"
        />

        <input {...getInputProps()} />
      </Box>

      <div>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ position: "relative", width: "100%", height: 400 }}>
              <Cropper
                image={cropImg}
                crop={crop}
                zoom={zoom}
                aspect={props.aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </Box>

            <Box>
              <Slider
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={async () => {
                  var file = dataURLtoFile(
                    await getCroppedImg(cropImg, croppedArea),
                    "hello.jpeg"
                  );

                  setImage(file);

                  handleClose();
                }}
                variant="contained"
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default DragsAndDrop;
