import React, { useContext } from "react";
import LeftDrawer from "./LeftDrawer";
import { Route, Routes, useNavigate } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard";
import Logout from "../logout";
import UserPermission from "../userPermission/UserPermission";

import { AdminDataContext } from "../../useContext/AdminContext";
import VerifiedRestaurant from "../restaurant/VerifiedRestaurant";
import UnVerifiedRestaurant from "../restaurant/UnVerifiedRestaurant";
import Category from "../Category";
import Cuisine from "../Cuisine";
import Owner from "../Owner";
import MenuItemUnverified from "./../menuItem/MenuItemUnverified";
import RestaurantMenuItem from "../menuItem/RestaurantMenuItem";
import Complain from "../complain/Complain";
import Coupon from "../coupon/Coupon";
import Delivery from "../delivery/Delivery";
import DeliveryUnverified from "../delivery/DeliveryUnverified";
import Customer from "../customer/Customer";
import Banner from "../banner/Banner";
import CustomerNotification from "../notification/CustomerNotification";
import RestaurantNotification from "../notification/RestaurantNotification";
import Brand from "../Brand";
import AppReview from "../appReview/AppReview";
import RestaurantTicket from "../restaurantTicket/RestaurantTicket";
import DeliveryTicket from "../deliveryTicket/DeliveryTicket";
import DeliveryNotification from "../notification/DeliveryNotification";
import DeliveryMonitor from "../delivery/DeliveryMonitor";
import DeliveryCash from "../delivery/DeliveryCash";
import Report from "../report/Report";
import DeliveryBoyNotAvailable from "../../order/DeliveryBoyNotAvailable";
import AllOrders from "../../order/AllOrders";
import RestaurantNotAccepted from "../../order/RestaurantNotAccepted";
import RestaurantOrders from "../../order/RestaurantOrders";
import MenuItemUpload from "../menuItem/MenuItemUpload";
import ASM from "../asm/ASM";
import DeliveryReport from "../delivery/DeliveryReport";
import AllDeliveryReport from "../delivery/AllDeliveryReport";
import OnlineRestaurant from "../restaurant/OnlineRestaurant";
// import Text from "../form/Test";

const ComponentRoute = () => {
  const { adminData } = useContext(AdminDataContext);
  const navigate = useNavigate();

  return (
    <>
      <LeftDrawer>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {adminData.permissions.restaurant ? (
            <Route
              path="/restaurant/verified"
              element={<VerifiedRestaurant />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/restaurant/unverified"
              element={<UnVerifiedRestaurant />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <Route
              path="/order/deliveryBoyNotAvailable"
              element={<DeliveryBoyNotAvailable />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <Route path="/order/allOrders" element={<AllOrders />} />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/order/restaurantNotAccepted"
              element={<RestaurantNotAccepted />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <Route
              path="/order/restaurantOrder"
              element={<RestaurantOrders />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route path="/restaurant/online" element={<OnlineRestaurant />} />
          ) : (
            <></>
          )}

          {adminData.permissions.menu ? (
            <Route path="/menuUnverified" element={<MenuItemUnverified />} />
          ) : (
            <></>
          )}
          {adminData.permissions.menu ? (
            <Route path="/upload/menu" element={<MenuItemUpload />} />
          ) : (
            <></>
          )}

          {adminData.permissions.menu ? (
            <Route
              path="/menu/:restaurantId"
              element={<RestaurantMenuItem />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.category ? (
            <Route path="/category" element={<Category />} />
          ) : (
            <></>
          )}
          {adminData.permissions.category ? (
            <Route path="/report" element={<Report />} />
          ) : (
            <></>
          )}
          {adminData.permissions.category ? (
            <Route path="/brand" element={<Brand />} />
          ) : (
            <></>
          )}

          {adminData.permissions.complain ? (
            <Route path="/complain" element={<Complain />} />
          ) : (
            <></>
          )}

          {adminData.permissions.complain ? (
            <Route path="/appReview/r" element={<AppReview />} />
          ) : (
            <></>
          )}
          {adminData.permissions.complain ? (
            <Route path="/ticket/r" element={<RestaurantTicket />} />
          ) : (
            <></>
          )}
          {adminData.permissions.complain ? (
            <Route path="/ticket/d" element={<DeliveryTicket />} />
          ) : (
            <></>
          )}
          {adminData.permissions.coupon ? (
            <Route path="/coupon" element={<Coupon />} />
          ) : (
            <></>
          )}
          {adminData.permissions.customer ? (
            <Route path="/customer" element={<Customer />} />
          ) : (
            <></>
          )}
          {adminData.permissions.notification ? (
            <Route
              path="/customerNotification"
              element={<CustomerNotification />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.notification ? (
            <Route
              path="/deliveryNotification"
              element={<DeliveryNotification />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.notification ? (
            <Route
              path="/restaurantNotification"
              element={<RestaurantNotification />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.banner ? (
            <Route path="/banner" element={<Banner />} />
          ) : (
            <></>
          )}

          {adminData.permissions.delivery ? (
            <Route path="/delivery" element={<Delivery />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route path="/deliveryReport" element={<DeliveryReport />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route path="/allDeliveryReport" element={<AllDeliveryReport />} />
          ) : (
            <></>
          )}

          {adminData.permissions.delivery ? (
            <Route path="/deliveryMonitor" element={<DeliveryMonitor />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route path="/deliveryCash" element={<DeliveryCash />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route
              path="/deliveryUnverified"
              element={<DeliveryUnverified />}
            />
          ) : (
            <></>
          )}

          {adminData.permissions.cuisine ? (
            <Route path="/cuisine" element={<Cuisine />} />
          ) : (
            <></>
          )}
          {adminData.permissions.owner ? (
            <Route path="/owner" element={<Owner />} />
          ) : (
            <></>
          )}

          {adminData.permissions.owner ? (
            <Route path="/asm" element={<ASM />} />
          ) : (
            <></>
          )}

          {adminData.permissions.permission ? (
            <Route path="/permission" element={<UserPermission />} />
          ) : (
            <></>
          )}
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </LeftDrawer>
    </>
  );
};

export default ComponentRoute;
