import * as React from "react";
import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "./listItem/ListItem";
import List from "@mui/material/List";
import useContent from "./listItem/useContent";
import CollapseItem from "./listItem/CollapseItem";
import { AdminDataContext } from "../../useContext/AdminContext";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

import { imgUrl } from "../../url";
const drawerWidth = 270;

function LeftDrawer(props) {
  const Content = useContent();
  const [expanded, setExpanded] = React.useState("");

  const handleExpandChange = (index) => () => {
    if (expanded[index] === true) {
      setExpanded({ [index]: false });
    } else {
      setExpanded({ [index]: true });
    }
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { adminData } = useContext(AdminDataContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Box
        display="flex"
        sx={{ my: 2, backgroundColor: "secondary" }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // marginLeft: 1,
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        >
          <img width="80%" alt="Logo" src="/images/logo.svg" />
        </Box>
        <Box
          sx={{
            marginRight: 2,
            mr: 2,
            display: { md: "none", sm: "flex" },
          }}
        >
          <img
            onClick={handleDrawerToggle}
            width="80%"
            alt="Logo"
            src="/images/logo.svg"
          />
        </Box>
      </Box>
      <List>
        {Content.map((data, index) => {
          return (
            <div key={index}>
              {data.items ? (
                <CollapseItem
                  key={index}
                  setMobileOpen={setMobileOpen}
                  expanded={expanded}
                  handleExpandChange={handleExpandChange}
                  data={data}
                  index={index}
                />
              ) : (
                <ListItem
                  key={index}
                  setMobileOpen={setMobileOpen}
                  setExpanded={setExpanded}
                  data={data}
                />
              )}
            </div>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        color="white"
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },

          backgroundColor: "primary.main",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current Admin"
              aria-haspopup="true"
              color="inherit"
            >
              {adminData.photo ? (
                <Avatar
                  src={`${imgUrl}/${adminData.photo}`}
                  sx={{ width: 34, height: 34 }}
                />
              ) : (
                <Avatar sx={{ width: 34, height: 34 }} />
              )}
            </IconButton>
            <Typography
              style={{
                paddingLeft: "15px",
                paddingTop: "18px",
                fontSize: "18ppx",
              }}
            >
              {adminData.name}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              backgroundColor: "drawerColor.main",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "drawerColor.main",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default LeftDrawer;
