import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import { imgUrl } from "../../url";
var FileSaver = require("file-saver");
function OnlineRestaurant() {
  const navigator = useNavigate();
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [restaurant, setRestaurant] = useState([]);
  const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editData, setEditData] = useState({});
  const editHandler = (data) => {
    setEditData({
      name: data.name,
      nonVeg: data.nonVeg,
      veg: data.veg,
      restaurantType: data.restaurantType,
      latitude: data.location.coordinates[1],
      longitude: data.location.coordinates[0],
      contactPersonName: data.contactPersonName,
      contactPersonPhone: data.contactPersonPhone,
      restaurantId: data._id,
      phone: data.owner.phone,
    });
    setEditModel(true);
  };
  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },

    {
      field: "avgCost",
      headerName: "Avg. Cost",
      width: 80,
    },
    {
      field: "visitable",
      headerName: "visitable",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visitable) => {
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/restaurants/visitable?restaurantId=${id}`,
              {
                visitable,
              }
            );

            snackbar(response.data.message, "success");
            getAllRestaurant(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visitable ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "veg",
      headerName: "Veg",
      width: 100,
    },
    {
      field: "nonVeg",
      headerName: "NonVeg",
      width: 100,
    },

    {
      field: "restaurantType",
      headerName: "Type",
      width: 100,
    },
    {
      field: "gstCertificate",
      headerName: "gstCertificate",
      width: 250,
      renderCell: (params) => {
        return params.row.gstCertificate ? (
          <>
            <Typography>{params.row.gstCertificate.gstNumber}</Typography>
            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  `${imgUrl}/${params.row.gstCertificate.documentFile}`,
                  "image.jpg"
                )
              }
            />
          </>
        ) : (
          "No data available"
        );
      },
    },

    {
      field: "fssaiCertificate",
      headerName: "fssaiCertificate",
      width: 200,
      renderCell: (params) => {
        return params.row.fssaiCertificate ? (
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Typography>{params.row.fssaiCertificate.certificateNo}</Typography>

            <CloudDownloadIcon
              onClick={() =>
                FileSaver.saveAs(
                  `${imgUrl}/${params.row.fssaiCertificate.documentFile}`,
                  "image.jpg"
                )
              }
            />
          </Box>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "menuItemFile",
      headerName: "Menu Item File",
      width: 250,
      renderCell: (params) => {
        return params.row.menuItemsFile ? (
          <>
            <Button
              onClick={() =>
                FileSaver.saveAs(
                  `${imgUrl}/${params.row.menuItemsFile}`,
                  "image.jpg"
                )
              }
            >
              Download
            </Button>
          </>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "menuItems",
      headerName: "MenuItem",
      width: 120,
      renderCell: (params) => {
        return !(params.row.menuItems.length === 0) ? (
          <Button onClick={() => navigator(`/menu/${params.row._id}`)}>
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "bank",
      headerName: "bank",
      width: 120,
      renderCell: (params) => {
        return params.row.bankDetail ? (
          <Button
            onClick={() => {
              setBankData(params.row.bankDetail);
              setBankModel(true);
            }}
          >
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 120,
      renderCell: (params) => {
        return params.row.address ? (
          <Button
            onClick={() => {
              setAddressData(params.row.address);
              setAddressModal(true);
            }}
          >
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },
    {
      field: "approve",
      headerName: "Approve",
      width: 120,
      renderCell: (params) => {
        return params.row.approved ? (
          <Typography>Approved</Typography>
        ) : (
          <Button>Approve</Button>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              editHandler(params.row);
            }}
            size="small"
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const url = `http://maps.google.com/maps?z=12&t=m&q=loc:${params.row.location.coordinates[1]}+${params.row.location.coordinates[0]}`;
              window.open(url, "_blank");
            }}
          >
            Location
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/restaurants/online?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setRestaurant(res.data.restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const onChangeEditHandler = (event) => {
    if (event.target.name === "nonVeg" || event.target.name === "veg") {
      setEditData({ ...editData, [event.target.name]: event.target.checked });
    } else {
      setEditData({ ...editData, [event.target.name]: event.target.value });
    }
  };
  const editSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/admin/restaurant/edit`, editData);
      snackbar(res.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setEditModel(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      snackbar(error.response.data.message, "error");
    }
  };

  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={restaurant}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllRestaurant(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllRestaurant(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>Bank Name : {bankData.bankName}</Typography>
          <Typography>
            Account Holder Name : {bankData.accountHolderName}
          </Typography>
          <Typography>Branch Name : {bankData.branch}</Typography>
          <Typography>Account Number : {bankData.accountNumber}</Typography>
          <Typography>
            Verified : {bankData.verified ? "True" : "False"}
          </Typography>
          {!bankData.verified ? (
            <Button variant="contained" onClick={verifiedBankHandler}>
              Verify Bank
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Modal>
      {/*  */}
      <Modal
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            Complete Address : {addressData.completeAddress}
          </Typography>
          <Typography>City : {addressData.city}</Typography>
          <Typography>State : {addressData.state}</Typography>
          <Typography>Landmark : {addressData.landmark}</Typography>
        </Box>
      </Modal>
      <Modal
        open={editModel}
        onClose={() => {
          setEditModel(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <Stack
            spacing={2}
            component="form"
            onSubmit={editSubmitHandler}
            sx={{}}
          >
            <Typography>Edit Restaurant</Typography>
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Contact Person Name"
              name="contactPersonName"
              value={editData.contactPersonName}
              onChange={onChangeEditHandler}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Contact Person Phone"
              name="contactPersonPhone"
              onChange={onChangeEditHandler}
              value={editData.contactPersonPhone}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Owner Phone"
              name="phone"
              onChange={onChangeEditHandler}
              value={editData.phone}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              label="Name"
              onChange={onChangeEditHandler}
              name="name"
              sx={{ width: "100%" }}
              value={editData.name}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Latitude"
              type="number"
              onChange={onChangeEditHandler}
              name="latitude"
              value={editData.latitude}
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Longitude"
              onChange={onChangeEditHandler}
              name="longitude"
              type="number"
              value={editData.longitude}
              variant="outlined"
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={editData.restaurantType}
              label="Restaurant Type"
              sx={{ width: "100%" }}
              onChange={onChangeEditHandler}
              name="restaurantType"

              // onChange={handleChange}
            >
              <MenuItem value={"QSR"}>QRS</MenuItem>
              <MenuItem value={"DSR"}>DSR</MenuItem>
            </Select>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={editData.nonVeg ? true : false}
                    name="nonVeg"
                    onChange={onChangeEditHandler}
                    label="Non Veg"
                  />
                }
                label="Non Veg"
              />
              <FormControlLabel
                control={
                  <Switch
                    onChange={onChangeEditHandler}
                    checked={editData.veg ? true : false}
                    name="veg"
                    label="Veg"
                  />
                }
                label="Veg"
              />
            </FormGroup>
            <Button type="submit" variant="contained">
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}

export default OnlineRestaurant;
