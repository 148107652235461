import React, { useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { AdminDataContext } from "../../useContext/AdminContext";

import axios from "axios";

const AddNewUser = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const passwordConfirm = useRef(null);
  const designation = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);

  const select = [
    { option: "Dashboard", id: "1" },
    { option: "restaurant", id: "2" },
    { option: "category", id: "3" },
    { option: "customer", id: "4" },
    { option: "cuisine", id: "5" },
    { option: "owner", id: "6" },
    { option: "menu", id: "7" },
    { option: "coupon", id: "8" },
    { option: "complain", id: "9" },
    { option: "delivery", id: "10" },
    { option: "banner", id: "11" },
    { option: "Admin permission", id: "12" },
    { option: "notification", id: "13" },
  ];

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      const data = {
        name: name.current.value,
        email: email.current.value,
        password: password.current.value,
        passwordConfirm: passwordConfirm.current.value,
        designation: designation.current.value,
        permissions: {
          dashboard: selectedOption.some((e) => e.option === "Dashboard"),
          restaurant: selectedOption.some((e) => e.option === "restaurant"),
          category: selectedOption.some((e) => e.option === "category"),
          customer: selectedOption.some((e) => e.option === "customer"),
          cuisine: selectedOption.some((e) => e.option === "cuisine"),
          owner: selectedOption.some((e) => e.option === "owner"),
          menu: selectedOption.some((e) => e.option === "menu"),
          coupon: selectedOption.some((e) => e.option === "coupon"),
          complain: selectedOption.some((e) => e.option === "complain"),
          delivery: selectedOption.some((e) => e.option === "delivery"),
          banner: selectedOption.some((e) => e.option === "banner"),
          notification: selectedOption.some((e) => e.option === "notification"),
          permission: selectedOption.some(
            (e) => e.option === "Admin permission"
          ),
        },
      };
      const res = await axios.post("/api/v1/admin/create/admin", { ...data });
      snackbar(res.data.message, "success");
      props.setRunEffect(true);
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box
        alignItems="center"
        justifyContent="center"
        // sx={{
        //   display: "flex",
        //   width: 140,
        //   height: 31,
        //   background: "linear-gradient(94.43deg, #54E6D8 -14.68%, #3E4095 87%)",
        //   borderRadius: "64px",
        // }}
        // margin={{
        //   lg: "10px 30px",
        //   md: "5xp 10px",
        //   sm: "5px 10px",
        //   xs: "5px 10px",
        // }}
      >
        <Typography
          variant="h4"

          // sx={{
          //   fontFamily: "Montserrat",
          //   fontStyle: "normal",
          //   fontWeight: 500,
          //   fontSize: "13px",
          //   lineHeight: "16px",
          //   color: "#fff",
          // }}
        >
          Add New User
        </Typography>
      </Box>
      <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            variant="outlined"
            placeholder="Name"
            name="name"
            inputRef={name}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Email"
            variant="outlined"
            placeholder="Email"
            name="email"
            inputRef={email}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Designation"
            variant="outlined"
            placeholder="Designation"
            name="designation"
            inputRef={designation}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Password"
            variant="outlined"
            placeholder="Password"
            name="password"
            inputRef={password}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            placeholder="Confirm Password"
            name="passwordConfirm"
            inputRef={passwordConfirm}
          />
        </Grid>
        <Grid item sm={12} spacing={2}>
          <Multiselect
            options={select}
            displayValue="option"
            onSelect={(list, item) => setSelectedOption(list)}
            onRemove={(list, item) => setSelectedOption(list)}
            value={select.option}
            name="select"
          />
          <Box display="flex" justifyContent="center" sx={{ margin: "20px" }}>
            <Button type="submit" variant="contained">
              Create Admin
            </Button>
          </Box>
        </Grid>
      </Grid>
      {/* <section className="vh-100 gradient-custom w-100">
        <div className="container py-0 h-100">
          <div className="row ">
            <div className="col-12 col-lg-9 col-xl-7">
              <div
                className="main-form"
                style={{ borderRadius: "15px", width: "170%" }}
              >
                <div className="card-body p-4 p-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            type="text"
                            id="firstName"
                            className="form-control form-control-lg"
                            placeholder="Name"
                            name="name"
                            ref={name}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-outline">
                          <input
                            type="text"
                            id="email"
                            className="form-control form-control-lg"
                            placeholder="Email"
                            name="email"
                            ref={email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-4 pb-2 w-100">
                        <div className="form-outline">
                          <input
                            type="text"
                            id="designation"
                            className="form-control form-control-lg"
                            placeholder="Designation"
                            name="designation"
                            ref={designation}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4 pb-2">
                        <div className="form-outline">
                          <input
                            type="password"
                            id="phoneNumber"
                            className="form-control form-control-lg"
                            placeholder="Password"
                            name="password"
                            ref={password}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 pb-2">
                        <div className="form-outline">
                          <input
                            type="password"
                            id="phoneNumber"
                            className="form-control form-control-lg"
                            placeholder="Confirm Password"
                            name="passwordConfirm"
                            ref={passwordConfirm}
                          />
                        </div>
                      </div>
                    </div>

                    <Multiselect
                      options={select}
                      displayValue="option"
                      onSelect={(list, item) => setSelectedOption(list)}
                      onRemove={(list, item) => setSelectedOption(list)}
                      value={select.option}
                      name="select"
                    />

                    <div className="d-flex justify-content-center">
                      <div className=" mt-4 pt-2">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          style={{
                            display: "flex",
                            width: 140,
                            height: 31,
                            background:
                              "linear-gradient(94.43deg, #54E6D8 -14.68%, #3E4095 87%)",
                            borderRadius: "64px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default AddNewUser;
