import React from "react";
import { NavLink } from "react-router-dom";
import "./listItem.css";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const ListItemComponent = ({ data, setMobileOpen, setExpanded }) => {
  return (
    <div>
      <ListItem
        component={NavLink}
        className="sideBar"
        to={`/${data.slug}`}
        onClick={() => {
          setMobileOpen(false);
          setExpanded("");
        }}
        sx={{ color: "#fff" }}
      >
        <ListItemIcon sx={{ color: "#fff" }}>
          <data.icon />
        </ListItemIcon>
        <ListItemText primary={data.text} />
      </ListItem>
    </div>
  );
};

export default ListItemComponent;
