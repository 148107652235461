import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../../useContext/AdminContext";

function DeliveryCash() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [amount, setAmount] = useState([{ _id: "", amount: 0 }]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLog([]);
  };
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "Name",
      headerName: "Name",

      width: 150,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.deliveryPartner ? params.row.deliveryPartner.name : ""}
          </Typography>
        );
      },
    },
    {
      field: "cashAmount",
      headerName: "Amount",
      width: 250,
    },
    {
      field: "cashLimit",
      headerName: "Cash Limit",
      width: 250,
    },

    {
      field: "Log",
      headerName: "Log",
      width: 300,
      renderCell: (params) => {
        const onClickHandler = () => {
          handleOpen();
          setLog(params.row.deliveryPartnerCashLog);
        };
        return <Button onClick={onClickHandler}>View</Button>;
      },
    },

    {
      field: "Submitted",
      headerName: "Submitted",
      width: 450,
      renderCell: (params) => {
        const amountValue = amount.find(
          (element) => element._id === params.row._id
        );
        const amountHandler = async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/delivery/cash?deliveryPartnerCurrentLocationId=${params.row._id}`,
              { amount: amountValue.amount }
            );

            snackbar(response.data.message, "success");
            getAllComplain(page, limit, "", "");
            setAmount([{ _id: "", amount: 0 }]);
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Box component={"form"} onSubmit={amountHandler}>
            <TextField
              id="outlined-basic"
              //   label="amount"
              type="number"
              variant="outlined"
              size="small"
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              value={amountValue ? amountValue.amount : 0}
              onChange={(e) =>
                setAmount([
                  {
                    _id: params.row._id,
                    amount:
                      e.target.value < params.row.cashAmount
                        ? e.target.value
                        : params.row.cashAmount,
                  },
                ])
              }
              required
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        );
      },
    },

    {
      field: "Report",
      headerName: "Report",
      width: 150,
      renderCell: (params) => {
        return <Button>Download</Button>;
      },
    },
  ];

  useEffect(() => {
    getAllComplain(page, limit, "", "");
  }, []);

  const getAllComplain = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/delivery/cash?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllComplain(page, limit, "", "");
    if (item.length === 0) {
      getAllComplain(page, limit, "", "");
    } else {
      getAllComplain(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllComplain(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllComplain(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date And Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">{row.amountSubmitted}</TableCell>
                    <TableCell align="center">
                      {new Date(row.date).toLocaleString("en-GB", {
                        timeZone: "IST",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default DeliveryCash;
