import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Paper, Stack, Typography } from "@mui/material";
const Dashboard = () => {
  const [data, setData] = useState();
  const getData = async () => {
    try {
      const res = await axios.get("/api/v1/admin/dashboard");
      setData(res.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Stack>
      <Grid container>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          md={3}
        >
          <Paper sx={{ padding: "10px" }} elevation={3}>
            <Typography sx={{ textAlign: "center" }}>Today</Typography>
            <Typography>Customer: {data?.todayCustomer}</Typography>
            <Typography>
              DeliveryPartner: {data?.todayDeliveryPartner}
            </Typography>
            <Typography>Order: {data?.todayOrder}</Typography>
            <Typography>Restaurant: {data?.todayRestaurant}</Typography>
          </Paper>
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          md={3}
        >
          <Paper sx={{ padding: "10px" }} elevation={3}>
            <Typography sx={{ textAlign: "center" }}>Week</Typography>
            <Typography>Customer: {data?.weekCustomer}</Typography>
            <Typography>
              DeliveryPartner: {data?.weekDeliveryPartner}
            </Typography>
            <Typography>Order: {data?.weekOrder}</Typography>
            <Typography>Restaurant: {data?.weekRestaurant}</Typography>
          </Paper>
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          md={3}
        >
          <Paper sx={{ padding: "10px" }} elevation={3}>
            <Typography sx={{ textAlign: "center" }}>Month</Typography>
            <Typography>Customer: {data?.monthCustomer}</Typography>
            <Typography>
              DeliveryPartner: {data?.monthDeliveryPartner}
            </Typography>
            <Typography>Order: {data?.monthOrder}</Typography>
            <Typography>Restaurant: {data?.monthRestaurant}</Typography>
          </Paper>
        </Grid>
        <Grid
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          item
          md={3}
        >
          <Paper sx={{ padding: "10px" }} elevation={3}>
            <Typography sx={{ textAlign: "center" }}>Year</Typography>
            <Typography>Customer: {data?.yearCustomer}</Typography>
            <Typography>
              DeliveryPartner: {data?.yearDeliveryPartner}
            </Typography>
            <Typography>Order: {data?.yearOrder}</Typography>
            <Typography>Restaurant: {data?.yearRestaurant}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Dashboard;
