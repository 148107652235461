import React, { useState, useContext } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";
import { AdminDataContext } from "./../../useContext/AdminContext";
import axios from "axios";

const RestaurantNotification = () => {
  const [data, setData] = useState({});
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [image, setImage] = useState("");
  const onChangeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const sendNotification = async (event) => {
    event.preventDefault();
    try {
      if (image) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("image", image);
        formData.append("title", data.title);
        formData.append("message", data.message);

        const res = await axios.post(
          `/api/v1/admin/notification/restaurant`,
          formData
        );
        setData({ title: "", message: "" });
        setImage("");
        snackbar(res.data.message, "success");
        setIsLoading(false);
      } else {
        setIsLoading(true);

        const res = await axios.post(`/api/v1/admin/notification/restaurant`, {
          ...data,
        });
        setImage("");
        setData({ title: "", message: "" });
        snackbar(res.data.message, "success");
        setIsLoading(false);
      }
    } catch (error) {
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  return (
    <Box>
      <Typography>Restaurant Notification</Typography>
      <Grid
        container
        flexDirection={"column"}
        spacing={2}
        component="form"
        onSubmit={sendNotification}
      >
        <Grid item sm={12} md={6}>
          <TextField
            id="outlined-basic"
            label="Title"
            value={data.title}
            name="title"
            required
            fullWidth
            onChange={onChangeHandler}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TextField
            multiline
            rows={4}
            required
            name="message"
            value={data.message}
            id="outlined-basic"
            label="Message"
            fullWidth
            onChange={onChangeHandler}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <DragsAndDrop
            heading="Upload Image"
            inputName="Image"
            aspect={2 / 1}
            uploadFile={(x) => {
              setImage(x);
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Button type="submit" variant="contained">
            Send Notification
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RestaurantNotification;
