import { Box, Button, Typography, Stack, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import { saveAs } from "file-saver";
import { imgUrl } from "../../url";
var FileSaver = require("file-saver");
function UnVerifiedRestaurant() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [restaurant, setRestaurant] = useState([]);
  // const [currentRestaurant, setCurrentRestaurant] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [bankData, setBankData] = useState({});
  const [addressData, setAddressData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [addressModal, setAddressModal] = useState(false);

  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },

    {
      field: "menuType",
      headerName: "Type",
      width: 50,
      renderCell: (params) => {
        return params.row.menuItemType === "VEG" ? (
          <img src="/images/veg-icon.svg" alt="" />
        ) : (
          <img src="/images/non-veg-icon.svg" alt="" />
        );
      },
    },

    {
      field: "restaurantaa",
      headerName: "Restaurant",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.restaurant ? params.row.restaurant.name : "Null"}
          </Typography>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 50,
    },
    {
      field: "approve",
      headerName: "Approve",
      width: 120,
      renderCell: (params) => {
        return (
          <Button onClick={() => approveHandler(params.row._id)}>
            Approve
          </Button>
        );
      },
    },
  ];
  const approveHandler = async (id) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `/api/v1/admin/restaurants/menuItem?menuItemId=${id}`,
        {}
      );

      snackbar(response.data.message, "success");
      getAllRestaurant(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRestaurant(page, limit, "", "");
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllRestaurant = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/menuItems/unapproved?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setRestaurant(res.data.menuItems);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllRestaurant(page, limit, "", "");
    if (item.length === 0) {
      getAllRestaurant(page, limit, "", "");
    } else {
      getAllRestaurant(page, limit, item[0].sort, item[0].field);
    }
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/restaurants/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setRestaurant(res.data.Restaurant);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={restaurant}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllRestaurant(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllRestaurant(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>Bank Name : {bankData.bankName}</Typography>
          <Typography>
            Account Holder Name : {bankData.accountHolderName}
          </Typography>
          <Typography>Branch Name : {bankData.branch}</Typography>
          <Typography>Account Number : {bankData.accountNumber}</Typography>
          <Typography>
            Verified : {bankData.verified ? "True" : "False"}
          </Typography>
          {!bankData.verified ? (
            <Button variant="contained" onClick={verifiedBankHandler}>
              Verify Bank
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Modal>

      <Modal
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>
            Complete Address : {addressData.completeAddress}
          </Typography>
          <Typography>City : {addressData.city}</Typography>
          <Typography>State : {addressData.state}</Typography>
          <Typography>Landmark : {addressData.landmark}</Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default UnVerifiedRestaurant;
