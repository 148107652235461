import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import LogoutIcon from "@mui/icons-material/Logout";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { AdminDataContext } from "../../../useContext/AdminContext";
import PersonIcon from "@mui/icons-material/Person";
import DiscountIcon from "@mui/icons-material/Discount";
import Person4Icon from "@mui/icons-material/Person4";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StoreIcon from "@mui/icons-material/Store";

const useContent = () => {
  const { adminData } = useContext(AdminDataContext);

  const content = [
    {
      icon: DashboardIcon,
      text: "Dashboard",
      slug: "",
    },
  ];
  if (adminData.permissions.restaurant) {
    content.push({
      icon: RestaurantIcon,
      text: "Manage Restaurants",
      slug: "restaurant",
      items: [
        {
          text: "Verified",
          slug: "verified",
        },
        {
          text: "Unverified",
          slug: "unverified",
        },
        {
          text: "Online",
          slug: "online",
        },
      ],
      // restaurant/online
    });
  }

  if (adminData.permissions.restaurant) {
    content.push({
      icon: RestaurantIcon,
      text: "Manage Order",
      slug: "order",
      items: [
        {
          text: "Delivery Boy Not Available",
          slug: "deliveryBoyNotAvailable",
        },
        {
          text: "All Orders",
          slug: "allOrders",
        },
        {
          text: "Restaurant not accepted",
          slug: "restaurantNotAccepted",
        },
        {
          text: "Restaurant Orders",
          slug: "restaurantOrder",
        },
      ],
    });
  }

  if (adminData.permissions.menu) {
    content.push({
      icon: MenuIcon,
      text: "Unverified Menu",
      slug: "menuUnverified",
    });
  }
  if (adminData.permissions.menu) {
    content.push({
      icon: MenuIcon,
      text: "upload Menu",
      slug: "upload/menu",
    });
  }
  if (adminData.permissions.category) {
    content.push({
      icon: CategoryIcon,
      text: "Category",
      slug: "category",
    });
  }
  if (adminData.permissions.category) {
    content.push({
      icon: CategoryIcon,
      text: "Report",
      slug: "report",
    });
  }
  if (adminData.permissions.category) {
    content.push({
      icon: StoreIcon,
      text: "Brand",
      slug: "brand",
    });
  }
  if (adminData.permissions.cuisine) {
    content.push({
      icon: FoodBankIcon,
      text: "Cuisine",
      slug: "cuisine",
    });
  }
  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "Complain",
      slug: "complain",
    });
  }
  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "AppReview Restaurant",
      slug: "appReview/r",
    });
  }
  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "Restaurant Ticket",
      slug: "ticket/r",
    });
  }
  if (adminData.permissions.complain) {
    content.push({
      icon: HelpOutlineIcon,
      text: "Delivery Ticket",
      slug: "ticket/d",
    });
  }

  if (adminData.permissions.coupon) {
    content.push({
      icon: DiscountIcon,
      text: "Coupon",
      slug: "coupon",
    });
  }
  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Delivery",
      slug: "delivery",
    });
  }

  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Delivery Report",
      slug: "deliveryReport",
    });
  }

  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "All Delivery Report",
      slug: "allDeliveryReport",
    });
  }

  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Delivery Monitor",
      slug: "deliveryMonitor",
    });
  }
  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Delivery Cash",
      slug: "deliveryCash",
    });
  }

  if (adminData.permissions.delivery) {
    content.push({
      icon: DeliveryDiningIcon,
      text: "Unverified Delivery",
      slug: "deliveryUnverified",
    });
  }
  if (adminData.permissions.notification) {
    content.push({
      icon: NotificationsIcon,
      text: "Restaurant Notification",
      slug: "restaurantNotification",
    });
  }
  if (adminData.permissions.notification) {
    content.push({
      icon: NotificationsIcon,
      text: "Customer Notification",
      slug: "customerNotification",
    });
  }
  if (adminData.permissions.notification) {
    content.push({
      icon: NotificationsIcon,
      text: "Delivery Notification",
      slug: "deliveryNotification",
    });
  }

  if (adminData.permissions.banner) {
    content.push({
      icon: ViewCarouselIcon,
      text: "Banner",
      slug: "banner",
    });
  }

  if (adminData.permissions.owner) {
    content.push({
      icon: CategoryIcon,
      text: "Owner",
      slug: "owner",
    });
  }
  if (adminData.permissions.owner) {
    content.push({
      icon: CategoryIcon,
      text: "ASM",
      slug: "asm",
    });
  }

  if (adminData.permissions.customer) {
    content.push({
      icon: Person4Icon,
      text: "customer",
      slug: "customer",
    });
  }
  if (adminData.permissions.permission) {
    content.push({
      icon: PersonIcon,
      text: "Admin Permission",
      slug: "permission",
    });
  }
  content.push({
    icon: LogoutIcon,
    text: "Logout",
    slug: "logout",
  });
  return content;
};
export default useContent;
