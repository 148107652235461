import React, { useEffect, useContext } from "react";
import Login from "./components/login/Login";
import Loader from "./reusableComponent/isLoading/Loader";

import ComponentRoute from "./components/main/ComponentRoute";
import { useNavigate, Route, Routes } from "react-router-dom";
import axios from "axios";
import { AdminDataContext } from "./useContext/AdminContext";
import SnackbarComponent from "./reusableComponent/snackbarComponent/SnackbarComponent";

const App = () => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    setIsLoading,
    message,
    severity,
    openSnackbar,
    setOpenSnackbar,
    updateAdminData,
  } = useContext(AdminDataContext);
  const navigate = useNavigate();
  const getAdminDataApi = async () => {
    try {
      const res = await axios.get("/api/v1/admin/");
      setIsAuthenticated(true);
      updateAdminData(res.data.admin);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    getAdminDataApi();
  }, []);

  return (
    <>
      <Loader open={isLoading} setOpen={setIsLoading} />
      <SnackbarComponent
        message={message}
        severity={severity}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />
      {isAuthenticated ? (
        <ComponentRoute />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
        </Routes>
      )}
    </>
  );
};

export default App;
