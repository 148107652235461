import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DeliveryReport = () => {
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [deliveryPartner, setDeliveryPartner] = useState({});
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);

  const getDeliveryPartnerData = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/delivery/partner/search?query=${e.target.value}`
      );

      if (!(res.data.data.length === 0)) {
        setDeliveryPartners(res.data.data);
      } else {
        setDeliveryPartners([]);
      }
    } catch (error) {}
  };
  function toHoursAndMinutes(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(
        `/api/v1/admin/delivery/log/single?deliveryPartnerId=${deliveryPartner._id}`,
        dates
      );
      setLogData(res.data.data);
    } catch (error) {}
  };
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };
  console.log(dates);
  return (
    <Box>
      {" "}
      <Autocomplete
        // multiple
        aria-required
        fullWidth
        id="tags-outlined"
        options={deliveryPartners}
        getOptionLabel={(userData) =>
          `${userData.name} Phone : ${userData.phone}`
        }
        onInputChange={getDeliveryPartnerData}
        onChange={(event, value) => {
          console.log(value);
          setDeliveryPartner(value);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Delivery Partner"
            placeholder="Select Delivery Partner"
          />
        )}
      />
      {deliveryPartner ? (
        <Box>
          <TextField
            id="outlined-basic"
            type="date"
            label="Start Date"
            variant="outlined"
            name="startDate"
            onChange={onChangeDate}
          />
          <TextField
            id="outlined-basic"
            type="date"
            label="End Date"
            variant="outlined"
            name="endDate"
            onChange={onChangeDate}
          />
          {dates.startDate && dates.endDate ? (
            <>
              <Button onClick={getDeliveryPartnerLogData} variant="contained">
                Get Date
              </Button>
              {logData.map((item, i) => {
                return (
                  <Stack spacing={2} key={item._id}>
                    <Accordion sx={{ margin: "10px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {moment(item?.createdAt).format("DD/MM/YYYY")} work
                          for {toHoursAndMinutes(item.todayTotal)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item?.loginData.map((item2) => {
                          return (
                            <Typography>
                              {moment(item2?.startTime).format("hh:mm A")} to{" "}
                              {moment(item2?.endTime).format("hh:mm A")} for{" "}
                              {toHoursAndMinutes(item2.difference)}
                            </Typography>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DeliveryReport;
