import React from "react";
import { NavLink } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import "./listItem.css";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";

const CollapseItem = ({
  data,
  setMobileOpen,
  expanded,
  handleExpandChange,
  index,
}) => {
  return (
    <div>
      <>
        <ListItem onClick={handleExpandChange(index)} sx={{ color: "#fff" }}>
          <ListItemIcon sx={{ color: "#fff" }}>
            <data.icon />
          </ListItemIcon>
          <ListItemText primary={`${data.text}`} />
          {expanded[index] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.items &&
              data.items.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    className="sideBar"
                    sx={{ pl: 3, color: "#fff" }}
                    component={NavLink}
                    onClick={() => setMobileOpen(false)}
                    to={`/${data.slug}/${item.slug}`}
                  >
                    <ListItemIcon>
                      <CircleIcon sx={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                );
              })}
          </List>
        </Collapse>
      </>
    </div>
  );
};

export default CollapseItem;
