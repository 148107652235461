import { Box, Button, TextField, Grid, Switch } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../useContext/AdminContext";
import { imgUrl } from "../url";
import DragsAndDrop from "../reusableComponent/imageUpload/DragsAndDrop";

function Brand() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [brand, setBrand] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [createBrand, setCreateBrand] = useState(false);
  const handleOpen = () => setCreateBrand(true);
  const handleClose = () => setCreateBrand(false);
  const [image, setImage] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "visible",
      headerName: "Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visible) => {
          try {
            setLoading(true);
            const response = await axios.patch(`/api/v1/admin/brand/${id}`, {
              visible,
            });

            snackbar(response.data.message, "success");
            getAllBrand(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.image}`}
            alt=""
          />
        );
      },
    },
  ];

  useEffect(() => {
    getAllBrand(page, limit, "", "");
  }, []);

  const getAllBrand = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/brand?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setBrand(res.data.brand);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const createBrandHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image);

      const res = await axios.post(`/api/v1/admin/brand`, {
        ...data,
      });
      await axios.post(`/api/v1/admin/brand/${res.data.brand._id}`, formData);
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllBrand(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const handleSortModelChange = async (item) => {
    getAllBrand(page, limit, "", "");
    if (item.length === 0) {
      getAllBrand(page, limit, "", "");
    } else {
      getAllBrand(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/brand/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setBrand(res.data.brand);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createCategoryHandler}
  return (
    <Box>
      <Button onClick={handleOpen} variant="contained">
        Add brand
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={brand}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllBrand(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllBrand(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={createBrand}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item sx={12} component="form" onSubmit={createBrandHandler}>
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={data.title}
                required
                variant="outlined"
                onChange={handleChange}
              />
              <DragsAndDrop
                heading="Upload Image"
                inputName="Image"
                aspect={1 / 1}
                uploadFile={(x) => {
                  setImage(x);
                }}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default Brand;
