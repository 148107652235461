import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../useContext/AdminContext";

function Cuisine() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [cuisine, setCuisine] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [createCuisine, setCreateCuisine] = useState(false);
  const handleOpen = () => setCreateCuisine(true);
  const handleClose = () => setCreateCuisine(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "visible",
      headerName: "Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visible) => {
          try {
            setLoading(true);
            const response = await axios.patch(`/api/v1/admin/cuisine/${id}`, {
              visible,
            });

            snackbar(response.data.message, "success");
            getAllCuisine(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
  ];

  useEffect(() => {
    getAllCuisine(page, limit, "", "");
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllCuisine = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/cuisine?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setCuisine(res.data.cuisine);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const createCuisineHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await axios.post(`/api/v1/admin/cuisine`, {
        ...data,
      });
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllCuisine(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const handleSortModelChange = async (item) => {
    getAllCuisine(page, limit, "", "");
    if (item.length === 0) {
      getAllCuisine(page, limit, "", "");
    } else {
      getAllCuisine(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/cuisines/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setCuisine(res.data.cuisine);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createCuisineHandler}
  return (
    <Box>
      <Button onClick={handleOpen} variant="contained">
        Add cuisine
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={cuisine}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllCuisine(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllCuisine(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={createCuisine}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item sx={12} component="form" onSubmit={createCuisineHandler}>
              <TextField
                id="outlined-basic"
                fullWidth
                label="Title"
                name="title"
                value={data.title}
                required
                variant="outlined"
                onChange={handleChange}
              />

              <Button variant="contained" type="submit">
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default Cuisine;
