import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState, useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
const Report = () => {
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);
  const tableRef = useRef(null);
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(`/api/v1/admin/restaurant/report`, dates);
      setLogData(res.data.data);
    } catch (error) {}
  };
  function toFixed(num, fixed) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };
  return (
    <Box>
      <Box>
        <TextField
          id="outlined-basic"
          type="date"
          label="Start Date"
          variant="outlined"
          name="startDate"
          onChange={onChangeDate}
        />
        <TextField
          id="outlined-basic"
          type="date"
          label="End Date"
          variant="outlined"
          name="endDate"
          onChange={onChangeDate}
        />
        {dates.startDate && dates.endDate ? (
          <>
            <Button onClick={getDeliveryPartnerLogData} variant="contained">
              Get Date
            </Button>

            <Stack spacing={2}>
              <TableContainer component={Paper} ref={tableRef}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Contact Person Name</TableCell>
                      <TableCell align="center">Contact Person Phone</TableCell>
                      <TableCell align="center">Address</TableCell>
                      <TableCell align="center">Account Holder Name</TableCell>
                      <TableCell align="center">Account Number</TableCell>
                      <TableCell align="center">Bank Name</TableCell>
                      <TableCell align="center">Branch</TableCell>
                      <TableCell align="center">IFSC Code</TableCell>
                      <TableCell align="center">Orders</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.contactPersonName}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.contactPersonPhone}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.address.completeAddress}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.bankDetail.accountHolderName}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.bankDetail.accountNumber}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.bankDetail.bankName}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.bankDetail.branch}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.bankDetail.ifscCode}
                        </TableCell>

                        <TableCell align="center">
                          <TableContainer component={Paper}>
                            <Table aria-label="simple table1">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">S.No</TableCell>
                                  <TableCell align="center">Date</TableCell>
                                  <TableCell align="center">Order</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.orders.map((order, index2) => (
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {index2 + 1}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {order.date}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <TableContainer component={Paper}>
                                        <Table aria-label="simple table2">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center">
                                                S.No
                                              </TableCell>
                                              <TableCell align="center">
                                                OrderId
                                              </TableCell>
                                              <TableCell align="center">
                                                Menu Item Title
                                              </TableCell>
                                              <TableCell align="center">
                                                Restaurant Share
                                              </TableCell>
                                              <TableCell align="center">
                                                Restaurant TDS
                                              </TableCell>
                                              <TableCell align="center">
                                                Total Price
                                              </TableCell>
                                              <TableCell align="center">
                                                Total Tax
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesCommission
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesCommissionGST
                                              </TableCell>
                                              <TableCell align="center">
                                                deliveryShare
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesDeliveryShare
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesShare
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesTax
                                              </TableCell>
                                              <TableCell align="center">
                                                yBitesEarning
                                              </TableCell>
                                              <TableCell align="center">
                                                paymentMode
                                              </TableCell>
                                              <TableCell align="center">
                                                Order status
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {order.order.map(
                                              (orderData, index3) => (
                                                <TableRow>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {index3 + 1}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.orderId}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {
                                                      orderData.order
                                                        .menuItemTitle
                                                    }
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.restaurantShare.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.restaurantTDS.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.totalPrice.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.totalTax.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesCommission.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesCommissionGST.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.deliveryShare.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesDeliveryShare.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesShare.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesTax.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.yBitesEarning.toFixed(
                                                      2
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {
                                                      orderData.order
                                                        .paymentMode
                                                    }
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {orderData.order.status}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <DownloadTableExcel
              filename="DeliveryPartner Log"
              sheet="DeliveryPartner"
              currentTableRef={tableRef.current}
            >
              <Button variant="contained"> Export excel </Button>
            </DownloadTableExcel>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Report;
