import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useState, useRef } from "react";
import SingleDeliveryReportDataGrid from "./SingleDeliveryReportDataGrid";
import { DownloadTableExcel } from "react-export-table-to-excel";
const AllDeliveryReport = () => {
  const [dates, setDates] = useState({});
  const [logData, setLogData] = useState([]);
  const tableRef = useRef(null);
  const getDeliveryPartnerLogData = async (e) => {
    try {
      const res = await axios.post(`/api/v1/admin/delivery/log`, dates);
      setLogData(res.data.data);
    } catch (error) {}
  };
  const onChangeDate = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };
  return (
    <Box>
      <Box>
        <TextField
          id="outlined-basic"
          type="date"
          label="Start Date"
          variant="outlined"
          name="startDate"
          onChange={onChangeDate}
        />
        <TextField
          id="outlined-basic"
          type="date"
          label="End Date"
          variant="outlined"
          name="endDate"
          onChange={onChangeDate}
        />
        {dates.startDate && dates.endDate ? (
          <>
            <Button onClick={getDeliveryPartnerLogData} variant="contained">
              Get Date
            </Button>

            <Stack spacing={2}>
              <TableContainer component={Paper}>
                <Table ref={tableRef} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Delivery Boy</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          {index + 1}
                        </TableCell>

                        <TableCell align="center">{row.date}</TableCell>
                        <TableCell align="center">
                          {" "}
                          <SingleDeliveryReportDataGrid
                            data={row.deliveryPartner}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            <DownloadTableExcel
              filename="DeliveryPartner Log"
              sheet="DeliveryPartner"
              currentTableRef={tableRef.current}
            >
              <Button variant="contained"> Export excel </Button>
            </DownloadTableExcel>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default AllDeliveryReport;
