import {
  Box,
  Button,
  Input,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../../useContext/AdminContext";
import { imgUrl } from "../../url";

function DeliveryUnverified() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [solution, setSolution] = useState("");
  const [bankData, setBankData] = useState({});
  const [bankModel, setBankModel] = useState(false);
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const verifiedBankHandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/admin/restaurants/bank?bankDetailId=${bankData._id}`
      );

      snackbar(response.data.message, "success");
      getAllComplain(page, limit, "", "");
      setLoading(false);
      setBankModel(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "image",
      width: 100,
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Box
            component="img"
            width="100%"
            src={`${imgUrl}/${params.row.image}`}
          ></Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
    },

    // {
    //   field: "title",
    //   headerName: "Title",
    //   width: 250,
    // },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   width: 250,
    // },

    // {
    //   field: "restaurantName",
    //   headerName: "Restaurant",
    //   width: 300,
    //   renderCell: (params) => {
    //     return <Typography>{params.row.restaurant.name}</Typography>;
    //   },
    // },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const solutionHandler = async (e) => {
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/delivery/unverified?deliveryPartnerId=${params.row._id}`,
              {}
            );

            snackbar(response.data.message, "success");
            getAllComplain(page, limit, "", "");
            setSolution("");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return params.row.status === "pending" ? (
          <Button type="submit" onClick={solutionHandler} variant="contained">
            Approve
          </Button>
        ) : (
          <Typography>{params.row.status}</Typography>
        );
      },
    },
    {
      field: "bank",
      headerName: "bank",
      width: 120,
      renderCell: (params) => {
        return params.row.bankDetail ? (
          <Button
            onClick={() => {
              setBankData(params.row.bankDetail);
              setBankModel(true);
            }}
          >
            View
          </Button>
        ) : (
          "No data available"
        );
      },
    },
  ];

  useEffect(() => {
    getAllComplain(page, limit, "", "");
  }, []);

  const getAllComplain = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/delivery/unverified?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setComplain(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllComplain(page, limit, "", "");
    if (item.length === 0) {
      getAllComplain(page, limit, "", "");
    } else {
      getAllComplain(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllComplain(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllComplain(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={bankModel}
        onClose={() => setBankModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography>Bank Name : {bankData.bankName}</Typography>
          <Typography>
            Account Holder Name : {bankData.accountHolderName}
          </Typography>
          <Typography>Branch Name : {bankData.branch}</Typography>
          <Typography>Account Number : {bankData.accountNumber}</Typography>
          <Typography>
            Verified : {bankData.verified ? "True" : "False"}
          </Typography>
          {!bankData.verified ? (
            <Button variant="contained" onClick={verifiedBankHandler}>
              Verify Bank
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default DeliveryUnverified;
