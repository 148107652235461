import React, { useEffect, useState, useContext } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import AddNewUser from "./AddNewUser";
import axios from "axios";
import { AdminDataContext } from "../../useContext/AdminContext";

import { DataGrid } from "@mui/x-data-grid";
const UserPermission = () => {
  const [api, setApi] = useState([]);
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [runEffect, setRunEffect] = useState(true);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: true,
    },
    {
      field: "designation",
      headerName: "Designation",
      type: "number",
      width: 200,
      editable: true,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,

      sortable: false,
      renderCell: (params) => {
        return (
          <Button onClick={() => handleDelete(params.row._id)}>
            Delete {}
          </Button>
        );
      },
    },
  ];
  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get("api/v1/admin/get/admin");
      // console.log(res.data.admin)
      setApi(res.data.admin);
      // console.log(api)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // console.log(error)
    }
  };
  useEffect(() => {
    if (runEffect) {
      getData();
      setRunEffect(false);
    }
  }, [runEffect]);

  const handleDelete = async (del_id) => {
    try {
      setIsLoading(true);
      const res = await axios.delete(`api/v1/admin/delete/admin/${del_id}`);
      snackbar(res.data.message, "success");
      setIsLoading(false);
      getData();
    } catch (error) {
      snackbar(error.response.data.message, "success");
    }
    // Code for delete the users
  };

  return (
    <Stack spacing={2}>
      <AddNewUser setRunEffect={setRunEffect} />
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{
          marginBottom: "300px",
        }}
        margin={{
          lg: "10px 30px",
          md: "5xp 10px",
          sm: "5px 10px",
          xs: "5px 10px",
        }}
      >
        <Typography
          variant="h4"
          // sx={{
          //   fontFamily: "Montserrat",
          //   fontStyle: "normal",
          //   fontWeight: 500,
          //   fontSize: "13px",
          //   lineHeight: "16px",
          // }}
        >
          User Admin Permission
        </Typography>
      </Box>
      <Grid sx={{ height: 600, width: "100%" }} item sm={12}>
        {api ? (
          <DataGrid
            rows={api}
            getRowId={(row) => row._id}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Stack>
  );
};

export default UserPermission;
